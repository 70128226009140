'use client';

import * as React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { Button } from './atom/button';
import { usePathname, useRouter } from 'next/navigation';
import Image from 'next/image';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileCTA = ({ isOpen, onClose }: ModalProps) => {
  const router = useRouter();
  const pathname = usePathname();

  return (
    <div className="md:hidden">
      <Dialog.Root open={isOpen} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed z-[1069] inset-0 bg-black/50 data-[state=open]:animate-overlayShow" />

          <Dialog.Content
            aria-describedby="Call to action dialog"
            className={`w-screen h-fit fixed bottom-0 left-0 z-[1069] flex justify-center px-2`}
          >
            <div className="w-full bg-cta-gradient rounded-tl-xl rounded-tr-xl flex flex-col gap-6 pt-8 relative">
              <div className={`w-full px-6`}>
                <Dialog.Title className="text-3xl font-semibold text-white text-left">
                  Make posts, get paid on Fusion.
                </Dialog.Title>
              </div>

              <Dialog.Close>
                <Button
                  variant="ghost"
                  className="absolute right-4 top-4 w-fit h-fit appearance-none focus-visible:appearance-none focus:outline-none p-0 m-0"
                  aria-label="Close"
                >
                  <X size={16} className="stroke-white" />
                </Button>
              </Dialog.Close>

              <div className="flex flex-col gap-4 w-full px-6 md:px-0 z-20 pb-10">
                <Button
                  className="w-full md:w-36 text-purple-brand bg-white text-base font-semibold font-semi-bold hover:bg-slate-100 hover:text-black px-12"
                  onClick={() => router.push('/signup')}
                >
                  Sign up
                </Button>

                <Button
                  variant="outline"
                  className="w-full md:w-36 text-white bg-transparent text-base font-semibold border border-white py-1 px-12 hover:bg-slate-100"
                  onClick={() => {
                    router.push(`${pathname}?ref=signin`);
                    onClose();
                  }}
                >
                  Log in
                </Button>
              </div>

              <div className="absolute right-0 bottom-0">
                <Image
                  src={'/assets/background/cta_mobile_bottom.svg'}
                  alt="banner background"
                  width={20}
                  height={20}
                  className="w-20 h-20"
                />
              </div>
              <div className="absolute left-0 top-0">
                <Image
                  src={'/assets/background/cta_mobile_top.svg'}
                  alt="banner background"
                  width={20}
                  height={20}
                  className="w-20 h-20 "
                />
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default MobileCTA;
